var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.action + ' Active Domain',"dialogStyle":{ top: '10px' },"destroyOnClose":true},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{staticClass:"btn btn-outline-dark",on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]),_c('a-button',{staticClass:"btn btn-outline-primary",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit('ruleForm')}}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{ref:"url",staticStyle:{"margin":"unset"},attrs:{"label":"url","prop":"url"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.url.onFieldBlur();
                }},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})],1),_c('a-form-model-item',{ref:"system_name",staticStyle:{"margin":"unset"},attrs:{"label":"system_name","prop":"system_name"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.system_name.onFieldBlur();
                }},model:{value:(_vm.form.system_name),callback:function ($$v) {_vm.$set(_vm.form, "system_name", $$v)},expression:"form.system_name"}})],1),_c('a-form-model-item',{ref:"company_name",staticStyle:{"margin":"unset"},attrs:{"label":"company_name","prop":"company_name"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.company_name.onFieldBlur();
                }},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}})],1),_c('a-form-model-item',{ref:"copyright",staticStyle:{"margin":"unset"},attrs:{"label":"copyright","prop":"copyright"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.copyright.onFieldBlur();
                }},model:{value:(_vm.form.copyright),callback:function ($$v) {_vm.$set(_vm.form, "copyright", $$v)},expression:"form.copyright"}})],1),_c('a-form-model-item',{ref:"status",staticStyle:{"margin":"unset"},attrs:{"label":"status","prop":"status"}},[_c('a-select',{attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.datastatus),function(data){return _c('a-select-option',{key:data.kode,attrs:{"value":data.kode}},[_vm._v(_vm._s(data.label))])}),1)],1),_c('a-form-model-item',{ref:"notes",staticStyle:{"margin":"unset"},attrs:{"label":"notes","prop":"notes"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.notes.onFieldBlur();
                }},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}})],1)],1)],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }